const styles = {
  container: '',
  head: 'flex items-start',
  label: 'block font-medium text-white text-xs leading-6 text-sm mb-2',
  foot: '',
  description: 'text-[rgba(255,255,255,0.3)] text-xs mt-1 mt-2 text-sm',
  error: 'text-red-500 text-xs mt-2',
};

export default styles;
