"use client";

import {
  BlocksRenderer,
  type BlocksContent,
} from "@strapi/blocks-react-renderer";
import Image from "next/image";

import { handleFixImageUrl } from "helpers";

import { TNextClientComponent } from "types/next";

type TProps = {
  content: BlocksContent;
};

const BlockRendererClient: TNextClientComponent<TProps> = (props) => {
  const { content } = props;

  if (!content) return null;

  return (
    <BlocksRenderer
      content={content}
      blocks={{
        heading: (props) => {
          return <h1 {...props} className="font-bold text-xl mb-4" />;
        },
        paragraph: (props) => {
          return <p {...props} className="!text-sm leading-7" />;
        },
        image: ({ image }) => {
          return (
            <Image
              src={handleFixImageUrl(image.url)}
              width={image.width}
              height={image.height}
              alt={image.alternativeText || "no-alt"}
            />
          );
        },
      }}
    />
  );
};

export default BlockRendererClient;
