const base = {
  container: "",
  head: "flex justify-between",
  body: "relative rounded-md",
  foot: "",
  label: "block font-medium text-white text-xs",
  hint: "text-xs text-gray-500",
  leading: "pointer-events-none absolute inset-y-0 left-0 flex items-center",
  input:
    "block w-full bg-[rgba(255,255,255,0.1)] border !border-[rgba(255,255,255,0.1)] px-5 py-3 text-white",
  trailing: "pointer-events-none absolute inset-y-0 right-0 flex items-center",
  description: "text-[rgba(255,255,255,0.3)] text-xs mt-1",
  error: "text-red-500 text-xs mt-1",
};

const leading: TInputGroupStyles = {
  input: "pl-10",
};

const trailing: TInputGroupStyles = {};

const normal: TInputGroupStyles = {
  label: "leading-6 text-sm mb-2",
  hint: "leading-6",
  leading: "pl-3",
  input:
    "rounded-md border-gray-200 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm placeholder-[rgba(255,255,255,0.2)]",
  trailing: "pr-3",
  description: "mt-2 text-sm",
};

const inset: TInputGroupStyles = {
  container:
    "rounded-md px-3 pt-2.5 pb-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-primary-600",
  label: "text-xs",
  hint: "text-xs",
  input:
    "border-0 !ring-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm pl-0",
  description: "hidden",
};

const overlapping: TInputGroupStyles = {
  container: "relative",
  head: " absolute flex justify-between items-center left-0 -top-2 right-0 mx-2 z-10 w-full",
  label: "inline-block bg-white px-1 text-xs font-medium text-gray-900 ",
  hint: "text-xs mr-4 bg-white px-1",
  input:
    "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6",
  description: "hidden",
};

const pill: TInputGroupStyles = {
  head: "mx-px block px-4 text-sm font-medium leading-6 text-gray-900 mb-1 pr-6",
  foot: "mx-px block px-4 text-sm font-medium leading-6 text-gray-900 mt-1 pr-6",
  input:
    "block w-full rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6",
  trailing: "pr-2",
  description: "text-xs",
};

const floored: TInputGroupStyles = {
  body: "relative mt-2",
  label: "block text-sm font-medium leading-6 text-gray-900",
  input:
    "peer block w-full border-0 bg-gray-50 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 ring-0",
  description: "text-xs mt-2",
};

const invalid: TInputGroupStyles = {
  container: "ring-red-500",
  input:
    // 'block w-full border border-transparent text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:leading-6 pr-10',
    // 'block w-full border border-transparent text-red-900 ring-1  ring-inset ring-red-900 placeholder:text-[rgba(255,0,0,0.3)] focus:ring-2 focus:ring-inset focus:ring-red-500 pr-10',
    "block w-full !bg-[rgba(255,0,0,0.1)]  border !border-[rgba(255,0,0,0.1)] px-5 py-3 text-white",
  description: "text-sm text-red-700",
};

const disabled: TInputGroupStyles = {
  input:
    "block w-full !bg-[rgba(255,255,255,0.02)]  border !border-[rgba(255,255,255,0.02)] px-5 py-3 text-white",
};

const styles = {
  base,

  leading,
  trailing,

  normal,
  inset,
  overlapping,
  pill,
  floored,

  invalid,
  disabled,
};

export type TInputGroupStyles = any;
export default styles;
