const base = {
  container: "",
  head: "flex justify-between",
  body: "relative rounded-md",
  foot: "",
  label: "block font-medium text-white text-xs",
  hint: "text-xs text-gray-500",
  leading: "pointer-events-none absolute inset-y-0 left-0 flex items-center",
  input:
    "block w-full text-white flex bg-[rgba(255,255,255,0.1)] border !border-[rgba(255,255,255,0.1)] px-2 py-1 overflow-x-scroll text-xs",
  menuButton: "",
  menu: "bg-[#1C1C1C] border border-[rgba(255,255,255,0.2)] z-10 !px-0 !py-0 !w-full rounded-sm overflow-hidden !mt-1 absolute text-white",
  tagItem:
    "bg-gray-200 px-2 py-1 flex flex-row gap-x-1 items-center justify-between rounded-sm",
  tagItemText: "text-xs ",
  tagItemIconContainer:
    "!p-0 rounded-full hover:bg-gray-400 text-sm hover:text-white aspect-square w-4 flex items-center justify-center cursor-pointer",
  tagItemIcon: "",
  list: "!px-0 !w-full [&>*]:px-0 max-h-56 overflow-scroll !py-1",
  listGroupLabel: "",
  listItem:
    "!w-full !list-none hover:bg-primary-500 hover:text-white !py-2 !px-2",
  listDisabledItem: "",
  searchContainer:
    "!w-full !px-2 !py-2 border-b border-[rgba(255,255,255,0.2)] !text-[rgba(255,255,255,0.1)] flex gap-x-2",
  searchBox:
    "bg-[#1C1C1C] outline-none ring-none !text-[rgba(255,255,255,0.1)]",
  searchIcon: "!text-[rgba(255,255,255,0.1)] w-4 aspect-square mx-1",
  closeIcon: "",
  emptyState: "w-full inline-flex items-start justify-start px-4",
  trailing: "pointer-events-none absolute inset-y-0 right-0 flex items-center",
  description: "text-[rgba(255,255,255,0.3)] text-xs mt-1",
  error: "text-red-500 text-xs ml-5 mt-1",
};

const leading: TSelectMenuFormStyles = {
  input: "pl-10",
};

const trailing: TSelectMenuFormStyles = {};

const normal: TSelectMenuFormStyles = {
  label: "leading-6 text-sm mb-2",
  hint: "leading-6",
  leading: "pl-3",
  input:
    "rounded-md border-gray-200 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm placeholder-[rgba(255,255,255,0.2)]",
  trailing: "",
  description: "mt-2 text-sm",
};

const inset: TSelectMenuFormStyles = {
  container:
    "rounded-md px-3 pt-2.5 pb-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-primary-600",
  label: "text-xs",
  hint: "text-xs",
  input:
    "border-0 !ring-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm pl-0",
  description: "hidden",
};

const overlapping: TSelectMenuFormStyles = {
  container: "relative",
  head: " absolute flex justify-between items-center left-0 -top-2 right-0 mx-2 z-10 w-full",
  label: "inline-block bg-white px-1 text-xs font-medium text-gray-900 ",
  hint: "text-xs mr-4 bg-white px-1",
  input:
    "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6",
  description: "hidden",
};

const pill: TSelectMenuFormStyles = {
  head: "mx-px block px-4 text-sm font-medium leading-6 text-gray-900 mb-1 pr-6",
  foot: "mx-px block px-4 text-sm font-medium leading-6 text-gray-900 mt-1 pr-6",
  input:
    "block w-full rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6",
  trailing: "pr-2",
  description: "text-xs",
};

const floored: TSelectMenuFormStyles = {
  body: "relative mt-2",
  label: "block text-sm font-medium leading-6 text-gray-900",
  input:
    "peer block w-full border-0 bg-gray-50 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 ring-0",
  description: "text-xs mt-2",
};

const invalid: TSelectMenuFormStyles = {
  container: "ring-red-500",
  input:
    "block w-full !bg-[rgba(255,0,0,0.1)]  border !border-[rgba(255,0,0,0.1)]",
  // '!bg-[rgba(255,0,0,0.1)] block w-full border border-transparent text-red-900 ring-1  ring-inset ring-red-900 placeholder:text-[rgba(255,0,0,0.3)] focus:ring-2 focus:ring-inset focus:ring-red-500 pr-10',
  description: "text-sm text-red-700",
};

const disabled: TSelectMenuFormStyles = {
  input:
    "block w-full !bg-[rgba(255,255,255,0.02)]  border !border-[rgba(255,255,255,0.02)]",
};

const styles = {
  base,

  leading,
  trailing,

  normal,
  inset,
  overlapping,
  pill,
  floored,

  invalid,
  disabled,
};

export type TSelectMenuFormStyles = any;
export default styles;
