const styles = {
  container: "",

  body: "scale-45 sm:scale-75 lg:scale-100 flex flex-col items-center justify-center -m-16 sm:m-10",
  foot: {
    container: "max-w-4xl w-full grid sm:grid-cols-3 gap-x-4 gap-y-8 group",
  },

  item: {
    container:
      "sm:p-6  duration-100 rounded-md flex flex-col items-center gap-y-4 group-hover:bg-transparent hover:!bg-[rgba(255,255,255,0.1)]",
    image: "w-full !aspect-video rounded-sm bg-top bg-cover",
    heading: "text-md flex-grow text-white font-bold text-center",
    button: "cursor-pointer",
  },
};

export default styles;
