/*

https://tailwindui.com/components/application-ui/overlays/modals

*/

export type { TNotificationOverlayStyles } from './NotificationOverlay.styles';

export { default } from './NotificationOverlay';
export { default as styles } from './NotificationOverlay.styles';
