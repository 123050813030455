import {
  string as nativeString,
  number as nativeNumber,
  boolean as nativeBoolean,
  date as nativeDate,
  mixed as nativeMixed,
  object as nativeObject,
  array as nativeArray,
} from "yup";

const string = (options?: { isRequired?: boolean }) => {
  const result = nativeString();

  if (options?.isRequired) {
    return result.required("Psst! You forgot a field.");
  }

  return result;
};

const number = (options?: { isRequired?: boolean }) => {
  const result = nativeNumber();

  if (options?.isRequired) {
    return result.required("Psst! You forgot a field.");
  }

  return result;
};

const boolean = (options?: { isRequired?: boolean }) => {
  const result = nativeBoolean();

  if (options?.isRequired) {
    return result.required("Psst! You forgot a field.");
  }

  return result;
};

const date = (options?: { isRequired?: boolean }) => {
  const result = nativeDate();

  if (options?.isRequired) {
    return result.required("Psst! You forgot a field.");
  }

  return result;
};

const mixed = (options?: { isRequired?: boolean }) => {
  const result = nativeMixed();

  if (options?.isRequired) {
    return result.required("Psst! You forgot a field.");
  }

  return result;
};

const object = (options?: { isRequired?: boolean }) => {
  const result = nativeObject();

  if (options?.isRequired) {
    return result.required("Psst! You forgot a field.");
  }

  return result;
};

const array = (options?: { isRequired?: boolean }) => {
  const result = nativeArray();

  if (options?.isRequired) {
    return result.required("Psst! You forgot a field.");
  }

  return result;
};

const dateMenuFormValue = (options?: { isRequired?: boolean }) => {
  const result = nativeObject({
    startDate: nativeString().required("Psst! You forgot a field."),
    endDate: nativeString().required("Psst! You forgot a field."),
  });

  if (options?.isRequired) {
    return result.required("Psst! You forgot a field.");
  }

  return result;
};

const email = (options?: { isRequired?: boolean }) => {
  const result = nativeString();

  if (options?.isRequired) {
    return result
      .required("Psst! You forgot a field.")
      .email("The email address you provided is invalid.");
  }

  return result;
};

const telephone = (options?: { isRequired?: boolean }) => {
  const result = nativeString();

  // Only allow numbers and the '+' sign.
  const regex = /^[ 0-9\+]*$/;

  if (options?.isRequired) {
    return result
      .required("Psst! You forgot a field.")
      .matches(regex, "The telephone number you provided is invalid.");
  }

  return result;
};

export const useValidation = () => {
  return {
    validate: {
      nativeString,
      nativeNumber,
      nativeBoolean,
      nativeDate,
      nativeMixed,
      nativeObject,
      nativeArray,
      //
      string,
      number,
      boolean,
      date,
      mixed,
      array,
      object,
      email,
      telephone,
      //
      dateMenuFormValue,
    },
  };
};
