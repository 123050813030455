const styles = {
  container:
    "font-body flex justify-between items-center px-4 py-3 bg-[#1C1C1C] text-white",

  body: "flex items-center gap-x-4",
  foot: "",

  heading: "font-semibold",
  description: "",

  button: {
    container: "!text-black cursor-pointer",
  },
  icon: "",
};

export default styles;
