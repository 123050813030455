"use client";

import React from "react";

import { toClassName } from "helpers";

import { TNextClientComponent } from "types/next";

import Modal from "components/custom/Modal";
import BlockRendererClient from "components/strapi/BlockRendererClient";
import { Button } from "components/tailwind-ui/Button";
import Icon from "components/tailwind-ui/Icon";

import { styles } from ".";

type TProps = {
  item?: any;
};

const WhatWeOfferClientView: TNextClientComponent<TProps> = (props) => {
  const { item } = props;

  return (
    <div className={styles.container}>
      <Modal
        size="lg"
        trigger={({ handleOpen }) => (
          <Button outline onClick={handleOpen} className="cursor-pointer">
            More info
          </Button>
        )}
      >
        {() => (
          <div className="p-12 flex flex-col gap-y-4">
            <div className={styles.item.figure}>
              <Icon type="solid" accessor={item.attributes.iconAccessor} />
            </div>
            <div className="flex flex-col gap-y-4 divide-y divide-[rgba(255,255,255,0.1)]">
              <div className="flex flex-col gap-y-4">
                <h3 className={styles.item.heading}>{item.attributes.title}</h3>
                <p className={styles.item.tags}>{item.attributes.subtitle}</p>
              </div>
              <div
                className={toClassName(
                  styles.item.description,
                  "lg:max-h-[40rem] overflow-y-scroll pt-4 !text-sm",
                )}
              >
                <BlockRendererClient content={item?.attributes.moreInfo} />
              </div>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        size="lg"
        trigger={({ handleOpen }) => (
          <Button outline onClick={handleOpen} className="cursor-pointer">
            View example case
          </Button>
        )}
      >
        {() => (
          <div className="p-12 flex flex-col gap-y-4">
            <div className={styles.item.figure}>
              <Icon type="solid" accessor={item.attributes.iconAccessor} />
            </div>
            <div className="flex flex-col gap-y-4 divide-y divide-[rgba(255,255,255,0.1)]">
              <div className="flex flex-col gap-y-4">
                <h3 className={styles.item.heading}>{item.attributes.title}</h3>
                <p className={styles.item.tags}>{item.attributes.subtitle}</p>
              </div>
              <div
                className={toClassName(
                  styles.item.description,
                  "lg:max-h-[40rem] overflow-y-scroll pt-4 !text-sm",
                )}
              >
                <BlockRendererClient content={item?.attributes.exampleCase} />
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default WhatWeOfferClientView;
