/*

https://tailwindui.com/components/application-ui/overlays/modals

*/

export type { TModalOverlayProps } from './ModalOverlay';
export type { TModalOverlayStyles } from './ModalOverlay.styles';

export { default } from './ModalOverlay';
export { default as styles } from './ModalOverlay.styles';
