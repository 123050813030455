const styles = {
  container: "flex flex-row gap-x-4",
  item: {
    container: "scroll-mt-36 py-8 flex flex-col sm:flex-row gap-8",

    head: "",
    body: "flex flex-col gap-y-4",

    heading: "!text-white",
    description: "!text-white opacity-60 pt-2",
    figure:
      "w-12 h-12 aspect-square bg-[rgba(255,255,255,0.1)] flex items-center justify-center text-white",
    icon: "",
    tags: "uppercase text-xs tracking-widest text-[#87BCBA]",
  },
};

export default styles;
