const base = {
  container: "relative z-20",

  background: {
    container: "fixed inset-0 bg-[#000] bg-opacity-75 transition-opacity",
    transition: {
      enter: "ease-out duration-300",
      enterFrom: "opacity-0",
      enterTo: "opacity-100",
      leave: "ease-in duration-200",
      leaveFrom: "opacity-100",
      leaveTo: "opacity-0",
    },
  },

  layout: {
    container: "fixed inset-0 z-40 overflow-y-auto",
    content:
      "flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0",
  },

  panel: {
    container:
      "relative transform rounded-md bg-[#1C1C1C] text-white w-full shadow-xl transition-all sm:w-full text-left",
    transition: {
      enter: "ease-out duration-300",
      enterFrom: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
      enterTo: "opacity-100 translate-y-0 sm:scale-100",
      leave: "ease-in duration-200",
      leaveFrom: "opacity-100 translate-y-0 sm:scale-100",
      leaveTo: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
    },
  },
};

const xs: TModalOverlayStyles = {
  panel: {
    container: "sm:max-w-xs",
  },
};

const sm: TModalOverlayStyles = {
  panel: {
    container: "sm:max-w-sm",
  },
};

const md: TModalOverlayStyles = {
  panel: {
    container: "sm:max-w-md",
  },
};

const lg: TModalOverlayStyles = {
  panel: {
    container: "sm:max-w-2xl",
  },
};

const xl: TModalOverlayStyles = {
  panel: {
    container: "sm:max-w-4xl",
  },
};

const styles = {
  base,

  xs,
  sm,
  md,
  lg,
  xl,
};

export type TModalOverlayStyles = any;
export default styles;
