/*

https://www.npmjs.com/package/react-tailwindcss-select

*/

export type { TSelectMenuFormProps } from './SelectMenuForm';
export type { TSelectMenuFormStyles } from './SelectMenuForm.styles';

export { default } from './SelectMenuForm';
export { default as styles } from './SelectMenuForm.styles';
