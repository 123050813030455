"use client";

import { createContext } from "react";

export type NotificationContextType = {
  notifications: any[];
  createNotification: (notification: {
    title: string;
    description?: string;
    type: "info" | "success" | "warning" | "error";
    isAutoRemovable?: boolean;
  }) => string;
  removeNotification: (id: string) => void;
};

export default createContext<NotificationContextType>({
  notifications: [],
  createNotification: (_notification: {
    title: string;
    description?: string;
    type: "info" | "success" | "warning" | "error";
    isAutoRemovable?: boolean;
  }) => "",
  removeNotification: (_id: string) => null,
});
