const base = {
  container: {
    outer:
      "pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50 mt-24",
    inner: "flex w-full flex-col items-center space-y-4 sm:items-end",
  },
  item: {
    container: "relative",

    layout: {
      container: "fixed inset-0 overflow-hidden",
      content: "absolute inset-0 overflow-hidden",
      inner:
        "pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10",
    },

    panel: {
      container:
        "pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white !text-black shadow-lg ring-1 ring-black ring-opacity-5 border !border-gray-600",
      transition: {
        enter: "transform ease-out duration-300 transition",
        enterFrom: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2",
        enterTo: "translate-y-0 opacity-100 sm:translate-x-0",
        leave: "transition ease-in duration-100",
        leaveFrom: "opacity-100",
        leaveTo: "opacity-0",
      },
    },
  },
};

const xs: TNotificationOverlayStyles = {
  item: {
    panel: {
      container: "sm:max-w-xs",
    },
  },
};

const sm: TNotificationOverlayStyles = {
  item: {
    panel: {
      container: "sm:max-w-sm",
    },
  },
};

const md: TNotificationOverlayStyles = {
  item: {
    panel: {
      container: "sm:max-w-md",
    },
  },
};

const lg: TNotificationOverlayStyles = {
  item: {
    panel: {
      container: "sm:max-w-2xl",
    },
  },
};

const xl: TNotificationOverlayStyles = {
  item: {
    panel: {
      container: "sm:max-w-4xl",
    },
  },
};

const styles = {
  base,

  xs,
  sm,
  md,
  lg,
  xl,
};

export type TNotificationOverlayStyles = any;
export default styles;
