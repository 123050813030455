import { useFormik, FormikValues, FormikConfig } from 'formik';
import { useState } from 'react';
import { ObjectShape, object } from 'yup';

export const useForm = (props: {
  initialValues: FormikValues;
  onSubmit: (values: FormikValues) => void;
  validationSchema: ObjectShape;
  validateOnMount?: boolean;
}) => {
  const [isValidationActive, setIsValidationActive] = useState<boolean>(false);

  const params: FormikConfig<FormikValues> = {
    ...props,
    validationSchema: object(props?.validationSchema),
    enableReinitialize: true,
    validateOnChange: isValidationActive,
  };

  return {
    ...useFormik(params),
    setIsValidationActive,
    validationSchema: props?.validationSchema,
  };
};
