/*

https://tailwindui.com/components/application-ui/forms/input-groups

*/

export type { TInputGroupFormProps } from './InputGroupForm';
export type { TInputGroupStyles } from './InputGroupForm.styles';

export { default } from './InputGroupForm';
export { default as styles } from './InputGroupForm.styles';
