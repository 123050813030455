import { Dialog, Transition } from "@headlessui/react";
import { set, get } from "lodash";
import React, {
  Fragment,
  useState,
  FC,
  useMemo,
  ReactNode,
  useEffect,
} from "react";

import { calculateNestedKeys } from "helpers/calculate";
import { toClassName } from "helpers/format";
import { deepCopyObject } from "helpers/object";

import Icon from "components/tailwind-ui/Icon";

import { TModalOverlayStyles, styles as defaultStyles } from ".";

export type TModalOverlayProps = {
  trigger?: ({
    handleOpen,
    handleClose,
  }: {
    handleOpen: () => void;
    handleClose: () => void;
  }) => ReactNode;
  children: ({
    handleOpen,
    handleClose,
  }: {
    handleOpen: () => void;
    handleClose: () => void;
  }) => ReactNode;

  size?: "xs" | "sm" | "md" | "lg" | "xl";

  styles?: TModalOverlayStyles;

  onOpen?: () => void;
  onClose?: () => void;
};

const ModalOverlay: FC<TModalOverlayProps> = (props) => {
  const {
    children,
    trigger,
    size = "md",
    styles: stylesOverrides,
    onOpen,
    onClose,
  } = props;

  const [isOpen, setIsOpen] = useState(trigger ? false : true);

  const options = useMemo(
    () => ({
      handleOpen: () => setIsOpen(true),
      handleClose: () => setIsOpen(false),
    }),
    [],
  );

  const handleGenerateStyle = () => {
    const result = deepCopyObject(defaultStyles.base);
    const keys = calculateNestedKeys(defaultStyles.base);

    keys.forEach((key) => {
      set(
        result,
        key,
        toClassName(
          get(defaultStyles.base, key),

          size && get(defaultStyles[size], key),

          get(stylesOverrides, key),
        ),
      );
    });

    return result;
  };

  const styles = handleGenerateStyle();

  useEffect(() => {
    if (isOpen) {
      onOpen && onOpen();
    }
  }, [isOpen, onOpen]);

  useEffect(() => {
    if (!isOpen) {
      onClose && onClose();
    }
  }, [isOpen, onClose]);

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className={styles.container} onClose={setIsOpen}>
          <Transition.Child
            as={Fragment}
            enter={styles.background.transition.enter}
            enterFrom={styles.background.transition.enterFrom}
            enterTo={styles.background.transition.enterTo}
            leave={styles.background.transition.leave}
            leaveFrom={styles.background.transition.leaveFrom}
            leaveTo={styles.background.transition.leaveTo}
          >
            <div className={styles.background.container} />
          </Transition.Child>
          <div className={styles.layout.container}>
            <div className={styles.layout.content}>
              <Transition.Child
                as={Fragment}
                enter={styles.panel.transition.enter}
                enterFrom={styles.panel.transition.enterFrom}
                enterTo={styles.panel.transition.enterTo}
                leave={styles.panel.transition.leave}
                leaveFrom={styles.panel.transition.leaveFrom}
                leaveTo={styles.panel.transition.leaveTo}
              >
                <Dialog.Panel className={styles.panel.container}>
                  <div
                    className="text-white flex flex-col items-end p-8 text-lg absolute right-0"
                    onClick={() => setIsOpen(false)}
                  >
                    <Icon accessor="xmark" />
                  </div>
                  {children(options)}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {trigger && trigger(options)}
    </>
  );
};

export default ModalOverlay;
