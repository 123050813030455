"use client";

import React, { useEffect, useState } from "react";

import { toClassName } from "helpers";

import { TNextClientComponent } from "types/next";

import World from "components/custom/World";
import { Button } from "components/tailwind-ui/Button";

import { styles } from ".";

type TProps = {
  activities?: any;
};

const WhatWeDoClientView: TNextClientComponent<TProps> = (props) => {
  const { activities } = props;

  const [focusSelector, setFocusSelector] = useState(1);
  const [countryCodes, setCountryCodes] = useState<string[]>([]);

  useEffect(() => {
    const timer = setInterval(() => {
      setFocusSelector((value) => (value !== 3 ? value + 1 : 1));
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  console.log(activities);

  return (
    <>
      <ul className={styles.foot.container}>
        {activities?.map((item: any) => (
          <li
            key={item.id}
            className={toClassName(
              styles.item.container,
              focusSelector === item.attributes.sequenceNumber
                ? "bg-[rgba(255,255,255,0.1)]"
                : "",
            )}
            onMouseOver={() =>
              setCountryCodes(item.attributes.countryCodes.split(", "))
            }
            onMouseLeave={() => setCountryCodes([])}
          >
            <div
              style={{
                backgroundImage: `url(${item.attributes.image.data.attributes.url})`,
              }}
              className={styles.item.image}
            />
            <h3 className={styles.item.heading}>{item.attributes.title}</h3>
            <a href={item.attributes.url}>
              <Button outline className={styles.item.button}>
                More info
              </Button>
            </a>
          </li>
        ))}
      </ul>
      <div className={styles.body}>
        <div
          className={
            countryCodes?.length > 0
              ? countryCodes?.map((code) => code.toLowerCase()).join(" ")
              : activities?.[focusSelector - 1].attributes?.countryCodes
                  ?.split(", ")
                  ?.map((code: string) => code.toLowerCase())
                  ?.join(" ")
          }
        >
          <World />
        </div>
      </div>
    </>
  );
};

export default WhatWeDoClientView;
